<template>
  <div>
    <v-layout wrap pb-6>
      <v-flex xs11 pr-lg-8>
        <v-card height="500px" style="box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08)" class="pa-4">
          <v-layout pt-8 wrap justify-center>
            <v-flex xs10>
              <span style="
                  font-family: dmsansregular;
                  color: #29807c;
                  font-size: 20px;
                  font-weight: 500;
                ">
                Edit Your Profile
              </span>
            </v-flex>

            <v-flex xs12>
              <v-layout wrap justify-center class="dmsansregular" style="color: black; font-size: 12px; font-weight: 500">
                <v-flex xs12 lg10 pr-10 pt-4 v-if="role === 'Trader'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Name</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                      v-model="traderName" hide-details></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs12 lg10 pr-10 pt-4 v-if="role === 'Farmer'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Name</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                      v-model="farmerName" hide-details></v-text-field>
                  </v-form>
                </v-flex>
                <v-flex xs12 lg10 pr-10 pt-4 v-if="role === 'Customer'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Name</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                      v-model="customerName" hide-details></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs12 lg10 pr-10 pt-6 v-if="role === 'Trader'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Email</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                    :rules="emailRules"      v-model="traderemail" hide-details></v-text-field>
                  </v-form>
                </v-flex>

                <v-flex xs12 lg10 pr-10 pt-6 v-if="role === 'Customer'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Email</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                    :rules="emailRules"    v-model="customeremail" hide-details></v-text-field>
                  </v-form>
                </v-flex>
                <v-flex xs12 lg10 pr-10 pt-6 v-if="role === 'Farmer'">
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Email</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                    :rules="emailRules"    v-model="farmeremail" hide-details></v-text-field>
                  </v-form>
                </v-flex>
                <v-flex xs12 lg10 pr-10 pt-6>
                  <span class="dmsansregular" style="color: black; font-size: 14px; font-weight: bold">Phone Number</span>
                  <v-form @submit.prevent="validateInput">
                    <v-text-field disabled solo flat text background-color="#F5F5F5" dense color="#29807c" type="text"
                      v-model="phonenumber" hide-details></v-text-field>
                  </v-form>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs3 lg10>
              <v-layout wrap justify-end pl-10 pt-16>
                <v-flex xs3>

                  <v-btn @click="editProfile()" class="no-uppercase" width="130px" color="#13736f"><span style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: #FFFFFF;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">Save Changes
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
    
<script>
import axios from "axios";
export default {
  data() {
    return {
      profile: {},
      farmerName: {},
      traderName: {},
      emailRules: [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Valid email is required",
      ],
      customerName: {},
      farmeremail: {},
      role: "",
      phonenumber: {},
      customeremail: {},
    };
  },

  mounted() {
    this.getData();
  },

  methods: {

    getData() {
      this.appLoading = true;

      axios({
        method: "GET",
        url: "/user/profile",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.profile = response.data.data;
          this.phonenumber = this.profile.phone;
          this.role = this.profile.role;

          if (this.profile.traderId) {
            this.traderName = this.profile.traderId.name;
            this.traderemail = this.profile.traderId.email;
          }

          if (this.profile.farmerId) {
            this.farmerName = this.profile.farmerId.name;
            this.farmeremail = this.profile.farmerId.email;

          }

          if (this.profile.customerId) {
            this.customerName = this.profile.customerId.name;
            this.customeremail = this.profile.customerId.email;

          }
          this.appLoading = false;


        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    editProfile() {
      let dataToSend = {};

      if (this.profile.traderId) {
        dataToSend = {
          email: this.traderemail,
          name: this.traderName
        };
      }

      if (this.profile.farmerId) {
        dataToSend = {
          email: this.farmeremail,
          name: this.farmerName
        };
      }

      if (this.profile.customerId) {
        dataToSend = {
          email: this.customeremail,
          name: this.customerName
        };
      }

      axios({
        method: "POST",
        url: "/user/profile/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: dataToSend
      })
        .then((response) => {
          if (response.data.status == true) {
            this.editdailog = false;
            this.msg = response.data.msg;
            location.reload();
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },


  },
};
</script>
    